@import "~styles/includes/index.scss";
@import './style-overrides/button-primary.scss';
@import './style-overrides/button-secondary.scss';
@import './style-overrides/button-secondary-light.scss';
@import './style-overrides/button-tertiary-light.scss';
@import './style-overrides/button-tertiary-dark.scss';
@import './style-overrides/button-primary-light.scss';

.buttonWrapper {
	display: flex;
	align-items: center;

	&:hover {
		.docReader {
			svg {
				path {
					fill: $font-color-hover;
				}
			}
		}
	}
}

.buttonBase {
	cursor: pointer;
	font-weight: 500;
	font-size: 18px;
	border-radius: 0;
	padding: 0 32px;
	min-height: 64px;
	display: inline-flex;
	background: transparent;
	transition: none;
	margin-bottom: 3px; // Fix for cut-off box shadow

	&:hover {
		transition: none;
	}

	&:focus {
		box-shadow: none;
	}

	&:active {
		background: transparent;
	}

	&.loading {
		color: white;
		background: transparent;
		overflow: hidden;
		position: relative;

		> div {
			pointer-events: none;
		}

		.title,
		.icon {
			opacity: 0;
		}
	}

	@media (max-width: $breakpoint-small-medium) {
		height: 48px;
		padding: 0 20px;
		font-size: 14px;
	}

	@include focus-reset();
	@include primary-button();
	@include secondary-button();
	@include secondary-light-button();
	@include tertiary-light-button();
	@include tertiary-dark-button();
	@include primary-button-light();
}

.title {
	line-height: 32px;

	&.titleRight {
		margin-left: 8px;
	}
	&.titleLeft {
		margin-right: 8px;
	}
}

.loader {
	position: absolute;
}

.icon {
	display: flex;
	flex-shrink: 0;
	align-items: center;
}
