$primary-color: #0e397b;
$primary-color-darker: #012151;
$primary-color-darkest: #001a41;

$focus-color-light: rgba($primary-color, 0.4);

// Tints
$tint-2: #fafafa;
$tint-3: #f7f7f7;
$tint-5: #f2f2f2;
$tint-6: #f0f0f0;
$tint-8: #ebebeb;
$tint-10: #e6e6e6;
$tint-20: #cccccc;
$tint-30: #b3b3b3;
$tint-40: #999999;
$tint-50: #808080;
$tint-60: #666666;
$tint-70: #4d4d4d;
$tint-80: #333333;
$tint-90: #1a1a1a;

// Functional
$success: #1dbf02;
$error: #bd271e;
$warning: #f68721;
