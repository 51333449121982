@import "~styles/includes/index.scss";
.downloads {
	display: flex;
	flex-direction: column;

	&.dark {
		color: white;

		h2 {
			color: white;
		}
		button {
			color: white;
		}
	}
}

.header {
	margin-bottom: 32px;

	@media (max-width: $breakpoint-small-medium) {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 16px;
	}
}

.items {
	span {
		text-align: left;
		a {
			font-size: 16px;
		}
	}

	.darkItem {
		margin-bottom: 8px;
	}

	.lightItem {
		margin-bottom: 8px;
		span {
			color: white;
		}
	}

	.label {
		display: block;
		font-size: 14px;
		line-height: 32px;
		margin-left: 20px;
		overflow-wrap: break-word;
		max-width: 50%;
	}

	button {
		margin-bottom: 12px;
	}
}

.docReader {
	margin-left: 6px;
	border-style: none;
	vertical-align: text-bottom;
}
