@import "~styles/includes/index.scss";
.sidebarlink {
	.label {
		display: block;
		font-size: 14px;
		line-height: 32px;
	}

	span {
		font-size: 18px;
		text-align: left;

		@media (min-width: $breakpoint-small-medium) {
			a {
				font-size: 18px;
			}
		}
	}
}
