@import "~styles/includes/index.scss";
@keyframes spinner {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
.spinner div {
	position: absolute;
	width: inherit;
	height: inherit;
	border: inherit;
	border-top-color: inherit;
	border-radius: 50%;
}
.spinner div {
	animation: spinner 0.819672131147541s linear infinite;
	top: 100px;
	left: 100px;
}
.parent {
	min-width: 200px;
	min-height: 200px;
	margin-left: -100px;
	margin-right: -100px;
	display: inline-block;
	overflow: hidden;
}
.spinner {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.spinner div {
	box-sizing: content-box;
}
