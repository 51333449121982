@import "~styles/includes/index.scss";
.pagination {
	width: 100%;
	display: flex;
}

.skip {
	&.next,
	&.prev {
		color: $font-color-main;

		&:hover {
			text-decoration: underline;
		}

		svg path {
			fill: $font-color-main;
		}
		&.disabled {
			opacity: 0.5;
			svg path {
				fill: $font-color-main;
			}
		}

		@media (max-width: $breakpoint-xsmall) {
			display: none;
		}
	}
}

.pageNumbers {
	.pageNumber {
		width: 32px;
		height: 32px;
		border-radius: 50px;
		font-weight: $global-font-weight;
		font-size: 16px;

		&:not(:last-child) {
			margin-right: 8px;

			@media (max-width: $breakpoint-xsmall) {
				margin-right: 2px;
			}
		}
	}

	button,
	a {
		&.pageNumber {
			cursor: pointer;
			color: $font-color-main !important;
			text-decoration-line: underline;
			&:hover {
				font-weight: 500;
			}

			&.isSelected {
				font-weight: 500;
				cursor: auto;
			}
		}
	}
}

span.pageNumber {
	&:hover {
		background: transparent;
	}
}

.pageNumbers {
	margin: 0 4px;

	@media (max-width: $breakpoint-xsmall) {
		margin: 0;
	}
}
