@mixin secondary-button {
	&.secondary {
		& {
			border: 2px solid $global-primary-blue;
			box-sizing: border-box;
			box-shadow: 3px 3px 0px $color-tint-grey-30;
			background-color: white;
			color: $global-primary-blue;

			&:not(:disabled) {
				&:focus-visible {
					position: relative;
					box-shadow: none;
					@include focus-outline;

					::before {
						content: '';
						position: absolute;
						top: -6px;
						left: -6px;
						right: -6px;
						bottom: -6px;
						background: $color-primary-blue;
						z-index: -1;
					}
				}

				&:hover {
					border: 2px solid $color-tint-grey-20;
					box-shadow: 3px 3px 0px $color-tint-grey-30;
					color: $global-primary-blue;
				}

				&:active {
					border: 2px solid $color-tint-grey-20;
					box-shadow: 3px 3px 0px $color-tint-grey-30;
					color: $global-primary-blue;
					background: $color-tint-grey-60;
				}
			}

			&:disabled {
				border: 2px solid $global-primary-blue;
				box-sizing: border-box;
				box-shadow: 3px 3px 0px $color-tint-grey-30;
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.outlined {
			background-color: transparent;
			border-width: 2px;

			&:not(:disabled) {
				&:hover {
					border-color: lighten($color-tint-grey-89, 4%);
					background-color: lighten($color-tint-grey-89, 4%);
				}

				&:active {
					border-color: lighten($color-tint-grey-89, 2%);
					background-color: lighten($color-tint-grey-89, 2%);
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.ghost {
			background-color: transparent;
			border: none;

			&:not(:disabled) {
				&:hover {
					background-color: rgba($color-tint-grey-89, 0.5);
					color: $color-primary-blue;
				}

				&:active {
					background-color: rgba($color-tint-grey-89, 0.8);
					color: $color-primary-blue;
				}
			}
		}
	}
}
