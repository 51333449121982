@mixin tertiary-light-button {
	&.tertiary-light {
		& {
			padding: 0px;
			height: auto;
			background: transparent;
			color: #ffffff;
			border-radius: 0;
			border: 0;
			font-weight: $global-font-weight;
			font-size: 16px;
			min-height: auto;

			&:not(:disabled) {
				@include focus-reset();

				&:hover {
					text-decoration: underline;
				}

				&:active {
					text-decoration-line: underline;
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.outlined {
			background-color: transparent;
			border-width: 2px;

			&:not(:disabled) {
				&:hover {
					border-color: lighten($color-tint-grey-89, 4%);
					background-color: lighten($color-tint-grey-89, 4%);
				}

				&:active {
					border-color: lighten($color-tint-grey-89, 2%);
					background-color: lighten($color-tint-grey-89, 2%);
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.ghost {
			background-color: transparent;
			border: none;

			&:not(:disabled) {
				&:hover {
					background-color: rgba($color-tint-grey-89, 0.5);
					color: $color-primary-blue;
				}

				&:active {
					background-color: rgba($color-tint-grey-89, 0.8);
					color: $color-primary-blue;
				}
			}
		}
	}
}
