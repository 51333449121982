@mixin text-field-default() {
	border: 0;
	background-size: 0 100%;
	background-repeat: no-repeat;
	box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.08), 0 3px 2px -2px rgba(0, 0, 0, 0.08), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
}

@mixin text-field-focus() {
	background-color: white;
	outline: none;
	box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.08), 0 4px 4px -2px rgba(0, 0, 0, 0.08), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	background-size: 100% 100%;
}

@mixin text-field-default-error() {
	background-size: 100% 100%;
}
