@import "~styles/includes/index.scss";
@keyframes spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.spinner div {
	left: 43.5px;
	top: 19px;
	position: absolute;
	animation: spinner linear 1s infinite;
	background: black;
	width: 13px;
	height: 12px;
	border-radius: 6.5px / 6px;
	transform-origin: 6.5px 31px;
}
.spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -0.9166666666666666s;
	background: inherit;
}
.spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -0.8333333333333334s;
	background: inherit;
}
.spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.75s;
	background: inherit;
}
.spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.6666666666666666s;
	background: inherit;
}
.spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.5833333333333334s;
	background: inherit;
}
.spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.5s;
	background: inherit;
}
.spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.4166666666666667s;
	background: inherit;
}
.spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.3333333333333333s;
	background: inherit;
}
.spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.25s;
	background: inherit;
}
.spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.16666666666666666s;
	background: inherit;
}
.spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.08333333333333333s;
	background: inherit;
}
.spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
	background: inherit;
}
.parent {
	width: 16px;
	height: 16px;
	display: inline-block;
	padding-top: 3px;
	overflow: hidden;
	background: #ffffff;
}
.spinner {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.16);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.spinner div {
	box-sizing: content-box;
}
