@import "~styles/includes/index.scss";
@keyframes spinner-r {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
	}
	50% {
		animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
	}
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes spinner-s {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
	}
	50% {
		animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
	}
	0% {
		transform: translate(-12px, -12px) scale(0);
	}
	50% {
		transform: translate(-12px, -12px) scale(1);
	}
	100% {
		transform: translate(-12px, -12px) scale(0);
	}
}
.spinner > div {
	transform: translate(0px, -6px);
}
.spinner > div > div {
	animation: spinner-r 1s linear infinite;
	transform-origin: 100px 100px;
}
.spinner > div > div > div {
	position: absolute;
	transform: translate(100px, 92.8px);
}
.spinner > div > div > div > div {
	width: inherit;
	height: inherit;
	border-radius: 50%;
	background: inherit;
	animation: spinner-s 1s linear infinite;
}
.spinner > div > div:last-child {
	animation-delay: -0.5s;
}
.spinner > div > div:last-child > div > div {
	animation-delay: -0.5s;
	background: inherit;
}
.loadingio-spinner-interwind-4o7gqu16yfw {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
	background: white;
}
.spinner {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.spinner div {
	box-sizing: content-box;
}

.parent {
	margin-top: -100px;
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
}
