@import "~styles/includes/index.scss";
@mixin reset-button() {
	display: flex;
	cursor: pointer;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	font-size: 16px;
}

.pagination {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	position: relative;

	a {
		text-decoration: none;
		box-sizing: border-box;
	}
}

a,
button {
	&.pageNumber {
		@include reset-button();

		&:hover {
			color: black;
		}
	}
}

.pageNumbers {
	display: flex;
	align-items: center;
	justify-content: center;

	&.absoluteCenter {
		width: 100%;
		position: absolute;

		&.skip {
			z-index: 1;
		}
	}
}

.pageNumber {
	display: flex;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	color: grey;

	&.isSelected {
		color: black;
	}
}

.skip {
	@include reset-button();
	height: 40px;
	padding: 8px;
	align-items: center;
	color: grey;

	.icon svg path {
		fill: grey;
	}

	&.prev {
		margin-left: -8px;
		margin-right: auto;
		.icon {
			display: flex;
			margin-right: 8px;
		}
	}

	&.next {
		margin-right: -8px;
		margin-left: auto;
		.icon {
			display: flex;
			margin-left: 8px;
		}
		.label {
			order: -1;
		}
	}

	&.iconOnly {
		justify-content: center;
		align-items: center;
		width: 40px;
		.icon {
			margin: 0;
		}
	}

	&:hover {
		color: black;
		.icon svg path {
			fill: black;
		}
	}

	&.disabled {
		color: lightgray;
		pointer-events: none;

		.icon {
			svg path {
				fill: lightgray;
			}
		}

		&:focus {
			outline: none;
		}
	}
}

.mobileCenterText {
	color: grey;
}

.pagination.autoWidth {
	.skip {
		&.prev {
			margin-right: initial;
		}
		&.next {
			margin-left: initial;
		}
	}
}
