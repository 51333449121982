@mixin primary-button-light {
	&.primary-light {
		& {
			border: 2px solid white;
			box-sizing: border-box;
			box-shadow: 3px 3px 0px $color-tint-grey-30;
			background-color: white;
			color: $global-primary-blue;

			&:not(:disabled) {
				&:focus {
					border: 2px solid $color-primary-blue;
					@include focus-outline(true);
				}

				&:hover {
					border: 2px solid $color-tint-grey-20;
					color: $global-primary-blue;
				}

				&:active {
					border: 2px solid $color-tint-grey-20;
					box-shadow: 3px 3px 0px $color-tint-grey-30;
					color: $global-primary-blue;
					background: rgba(white, 0.95);
				}
			}

			&:disabled {
				border: 2px solid white;
				box-sizing: border-box;
				box-shadow: 3px 3px 0px $color-tint-grey-30;
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.outlined {
			background-color: transparent;
			border-width: 2px;
			color: white;

			&:not(:disabled) {
				&:hover {
					border-color: $color-tint-grey-89;
					background-color: $color-tint-grey-89;
				}

				&:active {
					border-color: rgba($color-tint-grey-89, 0.9);
					background-color: rgba($color-tint-grey-89, 0.9);
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.ghost {
			background-color: transparent;
			border: none;
			color: white;

			&:not(:disabled) {
				&:hover {
					background-color: rgba($color-tint-grey-89, 0.2);
					color: $color-primary-blue;
					border: none;
					color: white;
				}

				&:active {
					background-color: rgba($color-tint-grey-89, 0.4);
					color: $color-primary-blue;
					border: none;
					color: white;
				}
			}
		}
	}
}
