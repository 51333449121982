@import "~styles/includes/index.scss";
@import './text-field-mixins.scss';

.textFieldInput {
	max-width: 100%;
	width: 100%;
	height: 40px;
	position: relative;

	&:hover {
		.delete {
			visibility: visible;
		}
	}

	.delete {
		position: absolute;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 0;
		right: 12px;
		visibility: collapse;
		cursor: pointer;

		+ .length {
			right: 32px;
		}
	}

	.length {
		position: absolute;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 0;
		right: 12px;
		color: #333333;
		font-weight: 500;
		font-size: 12px;
	}

	input {
		width: 100%;
		height: 40px;
		box-sizing: border-box;
		font-size: 16px;
		padding: 12px;
		@include text-field-default();
		&:focus {
			@include text-field-focus();
		}
	}

	input.error {
		@include text-field-default-error();
		&:focus {
			@include text-field-focus();
		}
	}

	input.readOnly {
		&,
		&:focus {
			box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
			background-image: none;
		}

		&:hover {
			cursor: default;
		}
	}

	input.disabled {
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

		&:hover {
			cursor: not-allowed;
		}
	}

	input.withIconLeft {
		padding-left: 40px;
	}

	input.withIconRight {
		padding-right: 40px;
	}

	input.showLengthInput {
		padding-right: 40px;
	}
}

.icon {
	pointer-events: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.iconRight {
	left: auto;
	right: 12px;
}
