@mixin primary-button {
	&.primary {
		& {
			background: $global-primary-blue;
			box-shadow: 3px 3px 0px $color-tint-grey-30;
			color: white;

			&:not(:disabled) {
				&:focus-visible {
					border: 2px solid #ffffff;
					@include focus-outline;
				}

				&:hover {
					background: $color-tint-grey-60;
					box-shadow: 3px 3px 0px $color-tint-grey-30;
					color: $global-primary-blue;
				}

				&:active {
					background: #dadada;
					box-shadow: 3px 3px 0px $color-tint-grey-30;
					color: $global-primary-blue;
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&.outlined {
			background-color: transparent;
			color: $button-primary-background;
			border: 2px solid $button-primary-background;

			&:not(:disabled) {
				&:hover {
					background-color: $button-primary-background-hover;
					border-color: $button-primary-background-hover;
				}

				&:active {
					background-color: $button-primary-background-active;
					border-color: $button-primary-background-active;
				}
			}

			&:disabled {
				border: 2px solid rgba($button-primary-background, 0.5);
				cursor: not-allowed;
				color: rgba($button-primary-background, 0.5);
			}
		}

		&.ghost {
			background-color: transparent;
			color: $button-primary-background;

			&:not(:disabled) {
				&:hover {
					background-color: rgba($button-primary-background-hover, 0.05);
					color: $button-primary-background-hover;
				}

				&:active {
					background-color: rgba($button-primary-background-active, 0.08);
					color: $button-primary-background-active;
				}
			}

			&:disabled {
				cursor: not-allowed;
				color: rgba($button-primary-background, 0.5);
			}
		}
	}
}
