@import "~styles/includes/index.scss";
@import './global-styling/variables.scss';

$backgroundTransition: background-color 0.15s;
$boxShadowTransition: box-shadow 0.15s;

@mixin setSquared($maxSize) {
	height: #{$maxSize}px;
	width: #{$maxSize}px;
	padding: 0;
}

.buttonBase {
	box-sizing: border-box;
	text-decoration: none;
	border: none;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-decoration: none;
	background: $primary-color;
	color: white;
	transition: $backgroundTransition, $boxShadowTransition;

	&.sizeSmall {
		height: 32px;
		padding: 0 16px;
	}

	&.sizeBig {
		height: 48px;
		padding: 0 32px;
	}

	&.loading {
		color: white;
		background: rgba($primary-color, 0.3);
		cursor: default;
	}

	&.squared {
		@include setSquared(40);
		&.sizeSmall {
			@include setSquared(32);
		}
		&.sizeBig {
			@include setSquared(48);
		}
	}

	&.fullWidth {
		width: 100%;
	}

	&::-moz-focus-inner {
		border: 0;
	}

	&:disabled {
		color: white;
		background: rgba($primary-color, 0.3);
		cursor: not-allowed;
	}
}

.tooltip {
	z-index: 9999;
	display: flex;
	margin-left: 8px;
	font-size: 12px;
	text-decoration: underline;
	cursor: pointer;
}
